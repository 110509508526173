@import '~ant-design-vue/lib/style/themes/default.less';
@import '../../design/antd/variables.less';
@import '../../design/mixins.less';

@tigerdrone-logo-color : #D22027;

@gray-1: #ffffff;
@gray-2: #fafafa;
@gray-3: #f5f5f5;
@gray-4: #e8e8e8;
@gray-5: #d9d9d9;
@gray-6: #bfbfbf;
@gray-7: #8c8c8c;
@gray-8: #595959;
@gray-9: #262626;
@gray-10: #000000;

@primary-color: #14837B;
@secondary-color: #D22027;
@secondary-1: color(~`colorPalette('@{secondary-color}', 1) `);
@secondary-2: color(~`colorPalette('@{secondary-color}', 2) `);
@secondary-3: color(~`colorPalette('@{secondary-color}', 3) `);
@secondary-4: color(~`colorPalette('@{secondary-color}', 4) `);
@secondary-5: color(~`colorPalette('@{secondary-color}', 5) `);
@secondary-6: @secondary-color;
@secondary-7: color(~`colorPalette('@{secondary-color}', 7) `);
@secondary-8: color(~`colorPalette('@{secondary-color}', 8) `);
@secondary-9: color(~`colorPalette('@{secondary-color}', 9) `);
@secondary-10: color(~`colorPalette('@{secondary-color}', 10) `);

@primary-lighten-color: lighten(@primary-color,65%);

@white-hover-bg : @gray-3;
@text-muted : @gray-7;

@body-min-width: 320px;
@custom-scroll-width : 4px;
@custom-scroll-width-layout : 10px;


@footer-bg: @primary-8;
@footer-color: @text-color-inverse;

@font-family-title: 'Prompt',Helvetica,Arial,sans-serif;
@image-dir: "~@assets/images";


@zindex-layout-header : 101;
@layout-sidemenu-width : 250px;
@layout-sidemenu-collapsed-width: 80px;

@screen-mobile : @screen-xs-max;
@screen-tablet-small : @screen-md-max;
@screen-tablet : @screen-lg-max;
@screen-notebook : @screen-xl-max;
@layout-content-min-width : 850px;

@status-new-color: @info-color;
@status-in-progress-color: #FFC425;
@status-pending-color: #FFC425;
@status-pre-delivery-color: #F37735;
@status-wait-delivery-color: #F37735;
@status-close-color: @success-color;
@status-cancel-color: #000;

@ticket-new-color: @info-color;
@ticket-investigate-color: #FFC425;
@ticket-reopen-color: @info-color;
@ticket-resolved-color: #F37735;
@ticket-close-color: @success-color;
@ticket-pending-color: #FFC425;
@ticket-cancel-color: #000;

@part-break-screen: @screen-lg;

.return-modal {
	height: 600px;
	overflow: auto;
	overscroll-behavior: none;
	div {
		font-size: 0.7rem;
	}
}
.page-header {
	display: flex;
	flex-basis: 100%;
	flex-direction: row;
}
.service-detail-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background-color: @white;
	margin-bottom: 8px;
	border-radius: @border-radius-base;
	.service-col {
		flex-basis: 50%;
		display: flex;
		flex-wrap: nowrap;
		min-width: 0;
		border: 1px solid @gray-4;
		> label {
			font-family: @font-family-title;
			color: @primary-color;
			min-width: 100px;
			flex-basis: 100px;
			background-color: @gray-3;
			padding: 4px 8px;
		}
		> .service-val {
			padding: 4px 8px;
			min-width: 0;
			.service-val-row {
				margin-bottom: 2px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.return-row {
	margin-top: 12px;
	margin-bottom: 12px;
	.mobile {
		margin-bottom: 24px;
	}
	> label {
		color: @info-color;
		width: 150px;
		text-align: right;
		margin-right: 8px;
		display: inline-block;
		.mobile & {
			display: block;
			text-align: left;
			margin-bottom: 2px;
			width: auto;
		}
	}
	&.full > label {
		text-align: left;
		display: block;
		margin-bottom: 4px;
	}
	.return-value {
		display: inline-block;
		color: @text-color;
	}
	.return-input {
		display: inline-block;
		.mobile & {
			display: block;
		}
	}
	.return-time-range {
		display: block;
		margin-top: 8px;
		margin-bottom: 4px;
	}
	.help {
		color: @text-muted;
		margin-top: 2px;
		font-size: 0.95em;
		margin-left: 158px;
		.mobile & {
			margin-left: 0;
		}
	}
}
.consent {
	margin-top: 16px;
}
.signature {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: relative;
	padding: 10px;
	margin-top: 8px;
	margin-bottom: 0px;

	.signature-item {
		display: flex;
		align-items: center;
		flex-direction: column;
		position: relative;
	}
	.sign-button {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.signature-img {
	display: block;
	width: 100%;
}
.sign-area {
	display: block;
	align-items: center;
	width: 123.16px;
	height: 40px;
}
.page-remark {
	display: flex;
	margin-top: 8px;
	justify-content: left;
	align-items: left;
}
.refer-text {
	color: @primary-color;
	margin-bottom: 2rem;
}

@body-background: @gray-3;@font-family: Helvetica, Arial, sans-serif;@layout-body-background: @body-background;@layout-header-height: 64px;@outline-color: @info-color;@highlight-color: @info-color;@input-hover-border-color: @info-color;@label-color: @info-color;@label-required-color: @info-color;@item-hover-bg: @white-hover-bg;@menu-item-active-bg: lighten(@primary-1,15%);@menu-highlight-color: @primary-8;@menu-inline-toplevel-item-height: 48px;@link-color: @info-color;@link-hover-color: color(~`colorPalette('@{link-color}', 5) `);@link-active-color: color(~`colorPalette('@{link-color}', 7) `);@tooltip-bg: @info-color;@tooltip-arrow-color: @tooltip-bg;@tooltip-color: @white;@card-head-padding: 12px;@table-row-hover-bg: @gray-3;@back-top-bg: @secondary-6;@back-top-hover-bg: @secondary-3;@breadcrumb-base-color: @secondary-color;@breadcrumb-last-item-color: @primary-color;@breadcrumb-icon-font-size: 16px;@breadcrumb-link-color: @secondary-color;@breadcrumb-link-color-hover: @secondary-5;@breadcrumb-separator-color: @secondary-color;@breadcrumb-separator-margin: 0 @padding-xs;